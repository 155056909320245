import React, { useState, useEffect } from "react";
import TraySlideOut from "../common/TraySlideOut";
import Toggle from "../common/Toggle";
import { FormikProvider, useFormik } from "formik";
import Input from "../common/form/Input";
import Select from "../common/form/Select";
import DatePicker from "../common/form/DatePicker";
import Button from "../common/Button";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import ConfirmModal from "../common/ConfirmModal";
import * as Yup from "yup";
import {
  applicationConfig,
  cropPlantedOptions,
  inhibitorConfig,
} from "./configs";
import { generateNitrogenApplicationSchema } from "../../utils/validation";

const nitrogenAppPrefix = "Nitrogen_Applications";

const handlePercentInput = (formik, index, key, value) => {
  const n = `${nitrogenAppPrefix}-${index + 1}-n_percent`;
  const p = `${nitrogenAppPrefix}-${index + 1}-p_percent`;
  const k = `${nitrogenAppPrefix}-${index + 1}-k_percent`;

  const numericValue = parseFloat(value);

  if (!isNaN(numericValue)) {
    if (key === n || key === p || key === k) {
      formik.setFieldValue(key, numericValue / 100);
    } else {
      formik.setFieldValue(key, numericValue);
    }
  } else {
    formik.setFieldValue(key, value);
  }
};

const percentValueToShow = (index, key, value) => {
  const n = `${nitrogenAppPrefix}-${index + 1}-n_percent`;
  const p = `${nitrogenAppPrefix}-${index + 1}-p_percent`;
  const k = `${nitrogenAppPrefix}-${index + 1}-k_percent`;

  if (value === null || value === undefined) {
    return "";
  }

  if (key === n || key === p || key === k) {
    return value * 100;
  } else {
    return value;
  }
};

const handleAdvancedToggle = (formik, key, index, value) => {
  formik.setFieldValue(key, value);

  if (key.includes("advanced")) {
    if (value === false) {
      applicationConfig.fields.forEach((field) => {
        if (field.condition === "advanced") {
          const fieldKey = `${nitrogenAppPrefix}-${index + 1}-${field.key}`;
          if (formik.values[fieldKey] !== undefined) {
            formik.setFieldValue(fieldKey, "");
          }
        }
      });
    } else {
      formik.setFieldValue(
        `${nitrogenAppPrefix}-${index + 1}-direct_nitrogen`,
        ""
      );
    }
  }

  if (key.includes("inhibitor_used") && value === false) {
    const inhibitorTypeKey = `${nitrogenAppPrefix}-${index + 1}-inhibitor_type`;
    if (formik.values[inhibitorTypeKey] !== undefined) {
      formik.setFieldValue(inhibitorTypeKey, "");
    }
  }
};

const getDefaultInputs = (data) =>
  Object.entries(data).reduce((result, [key, value]) => {
    if (key === "Nitrogen_Inhibitors" || key === "Nitrogen_Applications") {
      if (value?.length) {
        value.forEach((nitrate, index) => {
          Object.entries(nitrate).forEach(([nitrateKey, nitrateValue]) => {
            result[`${key}-${index + 1}-${nitrateKey}`] = nitrateValue;
          });
          const nPercent =
            result[`${nitrogenAppPrefix}-${index + 1}-n_percent`];
          const amount = result[`${nitrogenAppPrefix}-${index + 1}-amount`];
          const type = result[`${nitrogenAppPrefix}-${index + 1}-type`];
          const units = result[`${nitrogenAppPrefix}-${index + 1}-units`];
          result[`${nitrogenAppPrefix}-${index + 1}-advanced`] =
            nPercent || amount || type || units ? true : false;

          if (
            result[`${nitrogenAppPrefix}-${index + 1}-inhibitor_used`] ===
            undefined
          ) {
            result[`${nitrogenAppPrefix}-${index + 1}-inhibitor_used`] = false;
          }
        });
        result[key] = value.length;
      }
    } else {
      result[key] = value;
    }
    return result;
  }, {});

const CropInfo = ({ data, open, setIsOpen, tractId, refreshData }) => {
  const [isLoading, setLoading] = useState(false);

  const defaultInputs = getDefaultInputs(data || {});

  const [isEditing, setIsEditing] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState(null);

  const [nitrogenAppCount, setNitrogenAppCount] = useState(0);

  const [newNitrogenApplications, setNewNitrogenApplications] = useState([]);

  const validationSchema = Yup.object().shape({
    Crop_Planted: Yup.string().required("Crop planted is required"),
    Year: Yup.number()
      .required("Year is required")
      .typeError("Year must be a number"),
    ...generateNitrogenApplicationSchema(nitrogenAppCount),
  });

  useEffect(() => {
    if (data && data.Nitrogen_Applications) {
      setNitrogenAppCount(data.Nitrogen_Applications.length);
    } else {
      setNitrogenAppCount(0);
    }
  }, [data]);

  const handleButtonClick = () => {
    refreshData();
    if (isEditing) {
      setIsEditing(false);
    } else {
      formik.resetForm({ values: defaultInputs });
      setIsEditing(true);
    }
  };

  const handleDeleteNitro = async (index) => {
    try {
      const nitrogenApplicationId =
        defaultInputs[`${nitrogenAppPrefix}-${index + 1}-_id`];
      if (!nitrogenApplicationId) {
        toast.error("No ID found for the selected application");
        return;
      }
      await request({
        method: "delete",
        url: `/tracts/${tractId}/crops/${data._id}/nitrogen/${nitrogenApplicationId}`,
      });
      toast.success(`Successfully deleted nitrogen application`);
      refreshData();
    } catch (error) {
      toast.error(`Error deleting nitrogen application: ${error.message}`);
    }
  };

  const handleAddNitro = (e) => {
    e.preventDefault();

    const newNitrogenAppIndex = nitrogenAppCount + 1;

    setNewNitrogenApplications([
      ...newNitrogenApplications,
      newNitrogenAppIndex,
    ]);

    applicationConfig.fields.forEach((field) => {
      if (!field.number && !field.bool) {
        formik.setFieldValue(
          `${nitrogenAppPrefix}-${newNitrogenAppIndex}-${field.key}`,
          ""
        );
      }
    });

    setNitrogenAppCount(newNitrogenAppIndex);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: defaultInputs,
    validationSchema: validationSchema,

    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);

        const modifiedData = { general: {}, nitrogen: {} };
        const newNitrogenApplications = [];

        Object.keys(values).forEach((key) => {
          if (!(key in defaultInputs) || values[key] !== defaultInputs[key]) {
            if (key.startsWith(nitrogenAppPrefix)) {
              const [prefix, index, field] = key.split("-");

              if (!modifiedData.nitrogen[index]) {
                modifiedData.nitrogen[index] = {};
              }

              if (
                !defaultInputs[`${nitrogenAppPrefix}-${index}-date_applied`] &&
                field === "date_applied"
              ) {
                newNitrogenApplications.push({
                  date_applied:
                    values[`${nitrogenAppPrefix}-${index}-date_applied`],
                  emergent_type:
                    values[`${nitrogenAppPrefix}-${index}-emergent_type`],
                  type: values[`${nitrogenAppPrefix}-${index}-type`],
                  amount: values[`${nitrogenAppPrefix}-${index}-amount`],
                  units: values[`${nitrogenAppPrefix}-${index}-units`],
                  inhibitor_used:
                    values[`${nitrogenAppPrefix}-${index}-inhibitor_used`],
                  inhibitor_type:
                    values[`${nitrogenAppPrefix}-${index}-inhibitor_type`],
                  direct_nitrogen:
                    values[`${nitrogenAppPrefix}-${index}-direct_nitrogen`],
                  k_percent: values[`${nitrogenAppPrefix}-${index}-k_percent`],
                  n_percent: values[`${nitrogenAppPrefix}-${index}-n_percent`],
                  p_percent: values[`${nitrogenAppPrefix}-${index}-p_percent`],
                  n_density: values[`${nitrogenAppPrefix}-${index}-n_density`],
                });
              } else {
                modifiedData.nitrogen[index][field] = values[key];
              }
            } else {
              modifiedData.general[key] = values[key];
            }
          }
        });
        if (Object.keys(modifiedData.general).length > 0) {
          await request({
            method: "patch",
            url: `/tracts/${tractId}/crops/${data._id}`,
            data: modifiedData.general,
          });
          toast.success("Successfully updated crop data");
        }
        for (const [index, appData] of Object.entries(modifiedData.nitrogen)) {
          const formApplicationId =
            defaultInputs[`${nitrogenAppPrefix}-${index}-_id`];
          if (formApplicationId) {
            await request({
              method: "patch",
              url: `/tracts/${tractId}/crops/${data._id}/nitrogen/${formApplicationId}`,
              data: appData,
            });
          }
        }
        for (const newApp of newNitrogenApplications) {
          await request({
            method: "post",
            url: `/tracts/${tractId}/crops/${data._id}/nitrogen/`,
            data: newApp,
          });
          toast.success("Successfully added new nitrogen application");
        }

        refreshData();
        resetForm();
        setIsOpen(false);
        setIsEditing(false);
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const renderItem = ({
    label,
    key,
    suffix,
    bool,
    textarea,
    number,
    dropdown,
    options,
    date,
    condition,
    toggle,
    index,
    comment,
  }) => {
    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );
    const labelToShow = label || key.split("_").join(" ");
    let valueToShow = defaultInputs?.[key];
    if (key.includes(".")) {
      const [key1, key2] = key.split(".");
      valueToShow = defaultInputs?.[key1] && defaultInputs?.[key1][key2];
    }
    valueToShow = percentValueToShow(index, key, valueToShow);
    const showError = formik.errors[key];

    const shouldRender =
      !condition ||
      (condition === "inhibitor_used" &&
        formik.values[`${nitrogenAppPrefix}-${index + 1}-inhibitor_used`]) ||
      (condition === "advanced" &&
        formik.values[`${nitrogenAppPrefix}-${index + 1}-advanced`]);

    if (!shouldRender) return null;

    if (bool) {
      return (
        <div key={key} className="sm:col-span-1">
          <Toggle
            key={key}
            value={
              isEditing
                ? formik.values[key] !== undefined &&
                  formik.values[key] !== null
                  ? formik.values[key]
                  : valueToShow
                : formik.values[key]
            }
            label={labelToShow}
            disabled={!isEditing}
            onChange={(value) => {
              handleAdvancedToggle(formik, key, index, value);
            }}
          />
          {showError && (
            <div className="text-red-600">{formik.errors[key]}</div>
          )}
        </div>
      );
    }
    if (isEditing) {
      if (date) {
        return (
          <div>
            <DatePicker
              name={key}
              inputClassName="h-[38px]"
              label={labelToShow}
              value={formik.values[key] ?? valueToShow}
              onChange={(e) => formik.setFieldValue(key, e.target.value)}
              className={showError ? "border-red-600 m-2" : "m-2"}
            />
            {showError && (
              <div className="text-red-600">{formik.errors[key]}</div>
            )}
          </div>
        );
      } else if (dropdown) {
        return (
          <div>
            <Select
              name={key}
              label={labelToShow}
              extraOptions={options}
              value={formik.values[key] ?? valueToShow}
              onChange={(option) => {
                formik.setFieldValue(key, option);
              }}
              className={showError ? "border-red-600" : ""}
            />
            {showError && (
              <div className="text-red-600">{formik.errors[key]}</div>
            )}
          </div>
        );
      } else {
        return (
          <div>
            <Input
              name={key}
              label={labelToShow}
              value={percentValueToShow(
                index,
                key,
                formik.values[key] ?? valueToShow
              )}
              textarea={textarea}
              suffix={suffix}
              type={number ? "number" : "text"}
              disabled={
                (key === "Yield_Goal" &&
                  formik.values["Crop_Planted"] !== "Corn") ||
                (key === `${nitrogenAppPrefix}-${index + 1}-direct_nitrogen` &&
                  formik.values[
                    `${nitrogenAppPrefix}-${index + 1}-advanced`
                  ] === true)
              }
              onChange={(e) => {
                handlePercentInput(formik, index, key, e.target.value);
              }}
              className={showError ? "border-red-600" : ""}
            />
            {inputComment}
            {showError && (
              <div className="text-red-600">{formik.errors[key]}</div>
            )}
          </div>
        );
      }
    } else {
      let valueToDisplay = valueToShow;
      let labelToDisplay = labelToShow;
      if (typeof valueToShow === "boolean") {
        valueToDisplay = valueToShow ? "True" : "False";
      }
      if (date && valueToShow) {
        const parsedDate = new Date(valueToShow);
        if (!isNaN(parsedDate)) {
          valueToDisplay = parsedDate.toLocaleDateString();
        }
      }
      if (
        key.includes("inhibitor_type") &&
        formik.values[`${nitrogenAppPrefix}-${index + 1}-inhibitor_used`] ===
          false
      ) {
        return null;
      }
      if (
        key.includes("advanced") ||
        ((key.includes("n_percent") ||
          key.includes("n_density") ||
          key.includes("k_percent") ||
          key.includes("p_percent") ||
          key.includes("amount") ||
          key.includes("units") ||
          key.includes("type")) &&
          formik.values[`${nitrogenAppPrefix}-${index + 1}-advanced`] === false)
      ) {
        return null;
      }
      return (
        <div key={key} className="sm:col-span-1">
          <label className="font-semibold text-sm">{labelToDisplay}: </label>
          <div className="mt-1 text-sm">
            {valueToDisplay} {suffix || ""}
          </div>
        </div>
      );
    }
  };

  const gridClass = "w-100 grid grid-cols-1 md:grid-cols-2 gap-4 mt-3";

  if (!data) {
    return null;
  }

  const fields = (
    <div className="p-6">
      <div className={`${gridClass} mb-4 mt-6 first:mt-0 last:mb-0`}>
        {[
          { key: "Year", number: true },
          {
            key: "Crop_Planted",
            dropdown: true,
            options: cropPlantedOptions,
          },
          { key: "Crop_Yield", number: true },
          {
            key: "Crop_Yield_Units",
            dropdown: true,
            options: ["bushels / ac", "pounds / ac", "tons / ac"],
          },
          {
            key: "Yield_Goal",
            number: true,
          },
          { key: "Sprinkler_Acres", number: true },
          { key: "Flood_Acres", number: true },
          { key: "Other_Irr_Acres", number: true },
          { key: "Irrigation_Water_Applied", suffix: "inches", number: true },
          { key: "Water_Nitrate", suffix: "ppm NO-3", number: true },
          {
            key: "Tillage_Practice",
            dropdown: true,
            options: ["Conventional", "Minimum", "No-till"],
          },
        ].map(renderItem)}
      </div>
      <div className={`w-100 grid grid-cols-1 gap-4 mt-3 first:mt-0 last:mb-0`}>
        {[
          {
            key: "Note",
            label: "Note",
            textarea: true,
          },
        ].map(renderItem)}
      </div>
      <hr class="mt-6" />
      {defaultInputs?.Nitrogen_Applications ? (
        <>
          <div className="mb-4 mt-3">
            <div>
              <div className="flex items-center justify-between">
                <h5 className="text-base font-semibold my-2">
                  Nitrogen Applied
                </h5>
                {isEditing && (
                  <Button onClick={(e) => handleAddNitro(e)}>
                    Add Application
                  </Button>
                )}
              </div>
            </div>
            {Array.from({ length: nitrogenAppCount }, (_, index) => (
              <div
                key={index}
                className={`${gridClass} border-b pb-4 first:mt-0 last:mb-0 last:border-b-0 `}
              >
                <div className="font-bold col-span-2 flex items-center justify-between">
                  <span>Applications {index + 1}</span>
                  {isEditing &&
                    ((defaultInputs.Crop_Planted === "Corn" &&
                      nitrogenAppCount > 1) ||
                    defaultInputs.Crop_Planted !== "Corn" ? (
                      <MinusCircleIcon
                        className="w-5 h-5 cursor-pointer text-red-600"
                        onClick={() => {
                          setDeleteIndex(index);
                          setModalOpen(true);
                        }}
                      />
                    ) : null)}
                </div>
                {applicationConfig.fields
                  .map((item) => ({
                    ...item,
                    key: `${nitrogenAppPrefix}-${index + 1}-${item.key}`,
                  }))
                  .map((item) => renderItem({ ...item, index }))}
              </div>
            ))}
          </div>
          <hr class="mt-6" />
        </>
      ) : null}
      {defaultInputs?.Nitrogen_Inhibitors ? (
        <div className="mb-4 mt-3">
          <h5 className="text-base font-semibold inline my-2 mx-auto">
            Nirogen Inhibitor
          </h5>
          {Array(defaultInputs.Nitrogen_Inhibitors || 0)
            .fill("")
            .map((item, index) => (
              <div
                key={index}
                className={`${gridClass} border-b pb-4 first:mt-0 last:mb-0 last:border-b-0`}
              >
                <div className="font-bold col-span-2">
                  Inhibitors {index + 1}
                </div>
                {inhibitorConfig.fields
                  .map((item) => ({
                    ...item,
                    key: `Nitrogen_Inhibitors-${index + 1}-${item.key}`,
                  }))
                  .map(renderItem)}
              </div>
            ))}
        </div>
      ) : null}
      <div className="flex justify-start mt-5">
        {isEditing && (
          <div>
            <Button type="submit" disabled={isLoading} loading={isLoading}>
              Save
            </Button>
          </div>
        )}
        {data && data.approved === false && (
          <div>
            <Button onClick={handleButtonClick} className="ml-2">
              {!isEditing ? "Edit" : "Cancel"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <TraySlideOut
        title="Crop Information"
        onClose={() => {
          setTimeout(() => setIsOpen(false), 100);
          setIsEditing(false);
          refreshData();
        }}
        open={open}
      >
        {isEditing ? (
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>{fields}</form>
          </FormikProvider>
        ) : (
          fields
        )}
      </TraySlideOut>
      <ConfirmModal
        isOpen={isModalOpen}
        toggle={() => {
          setModalOpen(false);
        }}
        message="Are you sure you want to delete this application event?"
        onConfirm={() => {
          handleDeleteNitro(deleteIndex);
          setModalOpen(false);
          refreshData();
        }}
        warning={true}
      />
    </div>
  );
};

export default CropInfo;
