import React, { useMemo, useState } from "react";
import Modal from "../common/Modal";
import { FormikProvider, useFormik } from "formik";
import toast from "react-hot-toast";
import request from "../../utils/fetch";
import Button from "../common/Button";
import Toggle from "../common/Toggle";
import Input from "../common/form/Input";
import useFetch from "../../hooks/useFetch";
import Select from "../common/form/Select";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import { getBoundBox } from "../../utils/turfHelpers";
import GenericMap from "../map/GenericMap";

const fields = [
  {
    key: "soil_location_id",
    label: "Soil Location Name",
  },
  {
    key: "irrigated",
    label: "Irrigated",
    bool: true,
  },
  {
    key: "note",
    textarea: true,
    label: "Note",
    elementClassName: "col-span-2",
  },
];

const NewSoilLocationModalContent = ({
  formik,
  isLoading,
  tractData,
  mapSelectionData,
  setMapSelectionData,
}) => {
  const map = useMemo(() => {
    if (tractData) {
      const allPoints = [];
      (tractData.TractsDetail || [])
        .filter((c) => c?.geometry?.coordinates?.length)
        .forEach((c) => {
          if (c.geometry.type === "MultiPolygon") {
            c.geometry.coordinates
              .reduce((acc, [arr]) => [...acc, ...arr], [])
              .forEach((p) => allPoints.push(p));
          } else
            (c.geometry.coordinates[0] || []).forEach((p) => allPoints.push(p));
        });
      const viewport = calculatePolygonViewport({
        type: "Polygon",
        coordinates: [allPoints],
      }) || {
        latitude: 41.5886072190021,
        longitude: -103.58719705449793,
        zoom: 12.950430835225765,
      };
      const certGeo = convertToFeatureCollection(
        tractData.TractsDetail,
        "Cert_ID",
        ["Category"]
      );

      const soilGeo = mapSelectionData
        ? {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [mapSelectionData.lng, mapSelectionData.lat],
                },
                properties: {
                  id: "new_sample_location",
                },
              },
            ],
          }
        : undefined;

      let bboxMap;
      try {
        bboxMap = getBoundBox([
          ...(soilGeo ? soilGeo.features : certGeo.features),
        ]);
      } catch (e) {
        console.warn(e);
      }
      const shouldAddBBox = bboxMap?.some(
        (i) => i !== Infinity && i !== -Infinity
      );

      return (
        <GenericMap
          certGeo={certGeo}
          soilsGeo={soilGeo}
          viewportOverride={viewport}
          expandable
          irrigatedEditable
          editingEndpoint="certs"
          bboxMap={shouldAddBBox ? bboxMap : null}
          onIconClick={(type, id, location) => {
            setMapSelectionData({ id, ...location });
          }}
        />
      );
    }
    return null;
  }, [tractData, mapSelectionData, setMapSelectionData]);

  const renderItem = ({
    key,
    label,
    elementClassName,
    number,
    bool,
    textarea,
  }) => {
    if (key === "associated_tract") {
      return (
        <div
          key={key}
          className={`flex items-center justify-center ${
            elementClassName || "col-span-1"
          }`}
        >
          {tractData && (
            <Select
              label={label}
              extraOptions={tractData.TractsDetail.map((item) => ({
                label: item.Cert_ID,
                value: item._id,
              }))}
              onChange={(option) => {
                formik.setFieldValue(key, option);
              }}
              value={formik.values[key]}
              className="w-full"
            />
          )}
        </div>
      );
    }
    if (bool) {
      return (
        <div
          key={key}
          className={`flex items-center justify-center ${
            elementClassName || "col-span-1"
          }`}
        >
          <Toggle
            key={key}
            value={formik.values[key]}
            label={label}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
        </div>
      );
    }
    return (
      <div key={key} className={elementClassName || "col-span-1"}>
        <Input
          name={key}
          label={label}
          type={number ? "number" : "text"}
          textarea={textarea}
          onChange={(e) => {
            formik.setFieldValue(
              key,
              number && e.target.value
                ? parseFloat(e.target.value)
                : e.target.value
            );
            formik.setFieldTouched(key);
          }}
        />
      </div>
    );
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-x-5 gap-y-3">
            {fields.map(renderItem)}
          </div>
          <div className="w-4/6 aspect-video mt-3 mx-auto">
            <div className="text-sm font-medium leading-6 text-gray-900 mb-2 mx-auto">
              Select Soil Location
            </div>
            {map}
          </div>
          <div className="flex justify-end">
            <Button type="submit" className="w-fit mt-3" loading={isLoading}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </FormikProvider>
  );
};

const NewSoilLocation = ({ open, setIsOpen, refreshData }) => {
  const [isLoading, setLoading] = useState(false);
  const [mapSelectionData, setMapSelectionData] = useState({});

  const { data: tractData } = useFetch("/tracts");

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values, { resetForm }) => {
      try {
        if (
          !mapSelectionData.id ||
          !mapSelectionData.lat ||
          !mapSelectionData.lng
        ) {
          toast.error("Please select soil location");
          return;
        }

        setLoading(true);
        await request({
          method: "post",
          url: "/soils/",
          data: {
            ...values,
            associated_tract: mapSelectionData.id,
            latitude: parseFloat(mapSelectionData.lat),
            longitude: parseFloat(mapSelectionData.lng),
          },
        });
        if (refreshData) {
          refreshData();
        }
        toast.success("Successfully added crop");
        setLoading(null);
        setIsOpen(false);
        setMapSelectionData({});
        resetForm();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      isOpen={open}
      close={() => {
        setIsOpen(false);
        formik.resetForm();
        setMapSelectionData({});
      }}
      title="New Soil Location"
      Content={NewSoilLocationModalContent}
      contentClassName="soilLocationModalContent"
      contentProps={{
        formik,
        isLoading,
        tractData,
        mapSelectionData,
        setMapSelectionData,
      }}
    />
  );
};

export default NewSoilLocation;
