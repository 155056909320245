import React from "react";
import PropTypes from "prop-types";
import Input from "./Input";

export default function DatePicker({
  value = "",
  onChange,
  label,
  className = "",
  errorText = "",
  onBlur,
  type = "date",
  ...restProps
}) {
  const valueToShow = value
    ? type === "datetime-local"
      ? value.slice(0, 16)
      : value.slice(0, 10)
    : value;
  const handleChange = (newVal) => {
    if (type === "datetime-local") {
      onChange(newVal + (value ? value.slice(16) : ":00.000Z"));
    }
    onChange(newVal);
  };

  return (
    <Input
      value={valueToShow}
      onChange={handleChange}
      label={label}
      type={type}
      className={className}
      errorText={errorText}
      onBlur={onBlur}
      {...restProps}
    />
  );
}

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
  type: PropTypes.oneOf(["date", "datetime-local", "text"]),
};
