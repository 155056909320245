import React from "react";
import { twMerge } from "tailwind-merge";
import { BeatLoader } from "react-spinners";
import { oneOf, string, bool } from "prop-types";

const Button = ({
  className = "",
  loading = false,
  priority = "primary",
  children,
  ...restProps
}) => {
  let btnStyle = "";
  switch (priority) {
    case "secondary":
      btnStyle =
        "bg-white text-gray-700 ring-1 ring-inset ring-gray-200 hover:shadow-lg";
      break;
    default:
      btnStyle =
        "bg-primary text-white focus-visible:outline-primary-light enabled:hover:bg-primary-light shadow-sm";
      break;
  }

  return (
    <button
      className={twMerge(
        "flex h-10 justify-center items-center rounded-md px-7 py-2 text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-60",
        btnStyle,
        className
      )}
      {...restProps}
    >
      {loading ? <BeatLoader color="#ffffff" size={10} /> : children}
    </button>
  );
};

Button.propTypes = {
  priority: oneOf(["primary", "secondary"]),
  loading: bool,
  className: string,
};

export default Button;
