import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import HighLight from "../highlight";
import TableData from "../table";
import useFetch from "../../hooks/useFetch";
import Loading from "../common/Loading";
import FlowmeterUsageChart from "./FlowmeterUsageChart";
import { PencilSquareIcon, PlusCircleIcon } from "@heroicons/react/20/solid";
import Modal from "../common/Modal";
import { Formik, Form } from "formik";
import Button from "../common/Button";
import Input from "../common/form/Input";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { useUserContext } from "../../context/user";
import moment from "moment";
import { getChartData, getLastestValueForChart } from "../../utils/helpers";
import ImagePicker from "../common/ImagePicker";
import { getMeterReadSchema } from "../../utils/validation";
import AliasModal from "../common/AliasModal";
import { useMapRender } from "../../hooks/map/useMapRender";

const AddReadingModalContent = ({
  onSubmit,
  isLoading,
  setPicture,
  factor,
}) => {
  return (
    <Formik
      initialValues={{
        date: moment().format("YYYY-MM-DD"),
      }}
      onSubmit={onSubmit}
      validationSchema={() => getMeterReadSchema(factor)}
    >
      <Form className="space-y-4">
        <div>
          <Input required name="reading" label="Reading" type="number" />
          <span className="text-xs">
            {factor === 1
              ? "Enter Reading exactly as it is on face"
              : "Enter Reading from flowmeter face, don’t include decimal point"}
          </span>
        </div>
        <Input required name="date" label="Date" type="date" />
        <Input name="note" label="Note" textarea />
        <ImagePicker
          onSave={(pic) => setPicture(pic)}
          onRemove={() => setPicture(null)}
        />
        <Button type="submit" disabled={isLoading} loading={isLoading}>
          Save
        </Button>
      </Form>
    </Formik>
  );
};

const LIMIT = 10;

const FlowmeterPage = () => {
  const [page, setPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState();
  const [isLoading, setShowLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState();
  const [showAddReading, setShowAddReading] = useState(false);
  const [picture, setPicture] = useState(null);
  const [showAliasModal, setShowAliasModal] = useState(null);
  let [searchParams] = useSearchParams();

  const [userState] = useUserContext();

  const { data: allData, refresh } = useFetch("/meters");
  const { data: tractData } = useFetch("/tracts");
  const { data: reads, refresh: refreshReads } = useFetch("/meters/reads");
  const { data: usageData } = useFetch("/meters/usage?timeperiod=year");

  const meters = useMemo(() => allData?.meters || [], [allData?.meters]);

  useEffect(() => {
    if (meters?.length) {
      setShowLoading(false);
      setCanLoadMore(LIMIT * page < meters.length);
      if (meters.length && !selectedItem) {
        let defaultItem = meters[0];
        if (searchParams.get("id")) {
          const defaultSelectedMeter = meters.find(
            (item) =>
              item._id === searchParams.get("id") ||
              item.FM_ID === searchParams.get("id")
          );
          if (defaultSelectedMeter) {
            defaultItem = defaultSelectedMeter;
          }
        }
        setTimeout(() => {
          setSelectedItem(defaultItem);
        }, 200);
      } else if (!meters.length) {
        setSelectedItem(null);
      }
    }
  }, [meters, page, searchParams, selectedItem]);

  const handleScrollBottom = useCallback(() => {
    if (LIMIT * page < meters.length) {
      setTimeout(() => {
        setPage(page + 1);
      }, 3000);
    } else {
      setCanLoadMore(false);
    }
  }, [page, meters]);

  const handleAddReading = async (values) => {
    try {
      if (!showAddReading) {
        return;
      }
      setShowLoading(true);
      const formData = new FormData();
      if (picture) {
        formData.append("file", picture);
      }
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, key === "reading" ? parseInt(value) : value);
      });
      const headers = { "Content-Type": "multipart/form-data" };
      await request({
        method: "post",
        url: `/meters/${showAddReading.id}/AddReadWithFile`,
        data: formData,
        headers,
      });
      refreshReads();
      toast.success("Successfully added reading");
      setShowAddReading(null);
    } catch (e) {
      toast.error(e.response?.data?.error_message || e.message);
    } finally {
      setShowLoading(false);
    }
  };

  const highlight = useMemo(() => {
    if (!allData) {
      return [];
    }
    return [
      {
        title: "Total Water Usage",
        value: `${getLastestValueForChart(allData, "total_water_usage")} ac-in`,
        percentage: allData.total_water_usage_change,
        chartProps: {
          chartData: getChartData(allData, "total_water_usage"),
        },
      },
      {
        title: "Water Use per Acre",
        value: `${getLastestValueForChart(
          allData,
          "water_use_per_acre"
        )} in / ac`,
        percentage: allData.water_use_per_acre_change,
        chartProps: {
          chartData: getChartData(allData, "water_use_per_acre"),
          lineColor: "#9DC34F",
        },
      },
      {
        title: "Flowmeter Count",
        value: allData.flowmeter_count,
      },
    ];
  }, [allData]);

  const flowmeters = useMemo(() => {
    if (!meters) {
      return null;
    }
    return (
      <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full p-3">
        <div className="font-bold text-2xl">Flowmeter Info</div>
        <TableData
          enableScrollIntoItem
          selectedItem={selectedItem}
          data={meters}
          page={page}
          limit={LIMIT}
          canLoadMore={canLoadMore}
          handleScrollBottom={handleScrollBottom}
          headerItems={
            <>
              <div className="w-1/6 text-center">FLOWMETER ID</div>
              {/* <div className="w-1/6 text-center">ASSOC TRACT</div> */}
              <div className="w-1/6 text-center">METER LOCATION</div>
              <div className="w-1/6 text-center">SERIAL NO</div>
              <div className="w-1/6 text-center">FACTOR</div>
              <div className="w-1/6 text-center">ALIAS</div>
              <div className="w-1/6 text-center"></div>
            </>
          }
          renderRow={(item, index) => (
            <div
              className={`flex w-full py-4 cursor-pointer ${
                selectedItem?.FM_ID === item.FM_ID ? "bg-gray-1" : ""
              }`}
              onClick={() => setSelectedItem(item)}
            >
              <div className="w-1/6 text-center">{item.FM_ID}</div>
              {/* <div className="w-1/6 text-center">{item.assoc_tract}</div> */}
              <div className="w-1/6 text-center">{item.Meter_Location}</div>
              <div className="w-1/6 text-center">
                {item?.Meter_Details?.Serial_num}
              </div>
              <div className="w-1/6 text-center">
                {item?.Meter_Details?.factor}
              </div>
              <div className="w-1/6 flex justify-center">
                {item.Alias}
                <PencilSquareIcon
                  className="w-5 h-5 cursor-pointer ml-1"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAliasModal(item._id);
                  }}
                />
              </div>
              <div
                className={`w-1/6 text-center flex items-center justify-center gap-x-1 ${
                  !userState?.can_edit_meter ? "invisible" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAddReading({
                    id: item._id,
                    factor: item?.Meter_Details?.factor || 1,
                  });
                }}
              >
                <PlusCircleIcon
                  width={20}
                  height={20}
                  className="text-primary"
                />{" "}
                Add Reading
              </div>
            </div>
          )}
        />
      </div>
    );
  }, [
    canLoadMore,
    meters,
    handleScrollBottom,
    page,
    userState?.can_edit_meter,
    selectedItem,
  ]);

  const usageChart = useMemo(() => {
    const itemUsageData = usageData?.[selectedItem?._id];
    if (selectedItem && itemUsageData) {
      return <FlowmeterUsageChart usageData={itemUsageData} />;
    }
    return <div>No Usage Recorded</div>;
  }, [selectedItem, usageData]);

  const readings = useMemo(() => {
    const itemReadings = reads?.[selectedItem?._id];
    if (selectedItem && itemReadings) {
      const sortedReadings = itemReadings.sort((a, b) =>
        a.Date > b.Date ? -1 : 1
      );
      return (
        <TableData
          data={sortedReadings}
          page={1}
          canLoadMore={false}
          headerItems={
            <>
              <div className="flex-1 px-5">DATE</div>
              <div className="flex-1 px-5">READING</div>
              <div className="flex-1 px-5">USAGE CALCULATION</div>
              <div className="flex-1 px-5">USAGE (AC-IN)</div>
            </>
          }
          renderRow={(item, index) => (
            <div key={index} className="flex w-full py-4 cursor-pointer">
              <div className="flex-1 px-5">
                {moment(item.Date).utc().format("MM-DD-YYYY")}
              </div>
              <div className="flex-1 px-5">{item.Reading}</div>
              <div className="flex-1 px-5">
                {item.Man_Calc ? (
                  <span className="h-fit items-center gap-1 font-semibold bg-red-100 text-red-800 p-1.5 text-sm rounded px-2 py-0.5 inline">
                    Manual
                  </span>
                ) : (
                  <span className="h-fit items-center gap-1 font-semibold bg-green-100 text-green-800 p-1.5 text-sm rounded px-2 py-0.5 inline">
                    Automatic
                  </span>
                )}
              </div>
              <div className="flex-1 px-5">{item.Use_ai}</div>
            </div>
          )}
        />
      );
    }
    return <div>No Readings</div>;
  }, [reads, selectedItem]);

  const handleOnMeterClick = (type, id) => {
    const newSelectedMeter = (meters || []).find((item) => item._id === id);
    if (newSelectedMeter) {
      setSelectedItem(newSelectedMeter);
    }
  };

  const { renderMap } = useMapRender({
    tracts: tractData?.TractsDetail,
    meters,
    selectedItem,
    mapComponent: "flowmeter",
    extraMapProps: {
      expandable: true,
      onIconClick: handleOnMeterClick,
    },
  });

  if (isLoading && !meters) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">
        Flowmeter and Usage Overview
      </h2>
      <div className="my-6 flex gap-x-4">
        {highlight.map((item, index) => (
          <HighLight key={index} data={item} className="w-full" />
        ))}
      </div>
      <div className="flex flex-col lg:flex-row my-10 items-start gap-x-5">
        <div className="w-full lg:flex-1 h-[472px]">{renderMap()}</div>
        <div className="w-full lg:flex-1 mt-4 lg:mt-0">{flowmeters}</div>
      </div>
      <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 p-3">
        <div className="font-bold text-2xl">{`Flowmeter ${
          selectedItem?.FM_ID || ""
        } Usage`}</div>
        {usageChart}
      </div>
      <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 p-3">
        <div className="font-bold text-2xl">{`Flowmeter ${
          selectedItem?.FM_ID || ""
        } Readings`}</div>
        {readings}
      </div>
      <Modal
        small
        isOpen={!!showAddReading}
        close={() => setShowAddReading(null)}
        Content={AddReadingModalContent}
        contentProps={{
          onSubmit: handleAddReading,
          isLoading,
          setPicture,
          factor: showAddReading?.factor,
        }}
      />
      <AliasModal
        open={!!showAliasModal}
        setIsOpen={setShowAliasModal}
        table="meters"
        tableId={showAliasModal}
        refreshData={refresh}
      />
    </div>
  );
};

export default FlowmeterPage;
