import React, { useEffect, useMemo, useState } from "react";
import HighLight from "../highlight";
import TableData from "../table";
import useFetch from "../../hooks/useFetch";
import Loading from "../common/Loading";
import { useSearchParams } from "react-router-dom";
import AliasModal from "../common/AliasModal";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import { useMapRender } from "../../hooks/map/useMapRender";

const ChemigationPage = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [isLoading, setShowLoading] = useState(true);
  const [showAliasModal, setShowAliasModal] = useState(null);
  let [searchParams] = useSearchParams();

  const { data, refresh } = useFetch("/chemigations");
  const { data: tractData } = useFetch("/tracts");

  useEffect(() => {
    if (data) {
      setShowLoading(false);
      if (data.chemigations?.length && !selectedItem) {
        let defaultItem = data.chemigations[0];
        if (searchParams.get("id")) {
          const defaultSelectedChem = data.chemigations.find(
            (item) =>
              item._id === searchParams.get("id") ||
              item.Permit_No === searchParams.get("id")
          );
          if (defaultSelectedChem) {
            defaultItem = defaultSelectedChem;
          }
        }
        setSelectedItem(defaultItem);
      }
    }
  }, [data, searchParams, selectedItem]);

  const highlight = useMemo(() => {
    if (data) {
      return [
        {
          title: "Total Chemigations",
          value: data.total_chems,
        },
        {
          title: "Next Inspection Year",
          value: data.next_inspection,
        },
        {
          title: "Total Treated Acres",
          value: data.total_treated_acres,
        },
      ];
    }
    return null;
  }, [data]);

  const handleOnChemClick = (type, id) => {
    const newSelectedChem = (data.chemigations || []).find(
      (item) => item._id === id
    );
    if (newSelectedChem) {
      setSelectedItem(newSelectedChem);
    }
  };

  const { renderMap } = useMapRender({
    tracts: tractData?.TractsDetail,
    chemigations: data?.chemigations,
    selectedItem,
    mapComponent: "chemigations",
    extraMapProps: {
      expandable: true,
      onIconClick: handleOnChemClick,
    },
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">
        Chemigation Overview
      </h2>
      <div className="my-6 flex gap-x-4">
        {highlight &&
          highlight.map((item, index) => (
            <HighLight key={index} data={item} className="w-full" />
          ))}
      </div>
      <div className="flex flex-col lg:flex-row my-10 gap-x-5 items-start">
        <div className="w-full lg:flex-1 h-[472px]">{renderMap()}</div>
        <div className="w-full lg:flex-1 mt-4 lg:mt-0">
          {data && (
            <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full p-3">
              <TableData
                enableScrollIntoItem
                selectedItem={selectedItem}
                data={data.chemigations || []}
                page={1}
                headerItems={
                  <>
                    <div className="flex-1 text-left pl-3">Permit No</div>
                    <div className="flex-1 text-left">PERMIT YEAR</div>
                    <div className="flex-1 text-left">INJECTION UNIT</div>
                    <div className="flex-1 text-left">TREATED ACRES</div>
                    <div className="flex-1 text-center">ALIAS</div>
                  </>
                }
                renderRow={(item, index) => (
                  <div
                    className={`flex w-full py-4 cursor-pointer ${
                      selectedItem?._id === item._id ? "bg-gray-1" : ""
                    }`}
                    onClick={() => setSelectedItem(item)}
                  >
                    <div className="flex-1 pl-3">{item.Permit_No}</div>
                    <div className="flex-1">{item.Permit?.Report_Year}</div>
                    <div className="flex-1">{item.Permit?.Injection_Unit}</div>
                    <div className="flex-1">{item.Permit?.Treated_Acres}</div>
                    <div className="flex-1 flex justify-center">
                      {item.Alias}
                      <PencilSquareIcon
                        className="w-5 h-5 cursor-pointer ml-1"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAliasModal(item._id);
                        }}
                      />
                    </div>
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </div>
      {selectedItem && (
        <div className="mt-5 bg-white p-3 dark:bg-gray-900 dark:text-white">
          <h2 className="font-bold text-2xl">
            {/* {selectedItem.name} - {selectedItem.inspection_year} Permit Details */}
            Permit Details
          </h2>
          <div className="w-full p-5">
            <div className="flex">
              <div className="flex-1">
                Name:{" "}
                <span className="font-bold">
                  {selectedItem.Name ? selectedItem.Name : null}
                </span>
              </div>
              <div className="flex-1">
                Inspection Year:{" "}
                <span className="font-bold">
                  {selectedItem.Scheduled_Inspection_Year
                    ? selectedItem.Scheduled_Inspection_Year
                    : null}
                </span>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                Date Received:{" "}
                <span className="font-bold">
                  {selectedItem.Permit.Date_Received
                    ? moment(selectedItem.Permit.Date_Received)
                        .utc()
                        .format("MM-DD-YYYY")
                    : null}
                </span>
              </div>
              <div className="flex-1">
                Inspector:{" "}
                <span className="font-bold">
                  {selectedItem.Permit.Inspector}
                </span>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                Date Approved:{" "}
                <span className="font-bold">
                  {selectedItem.Permit.Date_Approved
                    ? moment(selectedItem.Permit.Date_Approved)
                        .utc()
                        .format("MM-DD-YYYY")
                    : null}
                </span>
              </div>
              <div className="flex-1">
                Inspection Date:{" "}
                <span className="font-bold">
                  {selectedItem.Permit.Inspection_Dates &&
                  selectedItem.Permit.Inspection_Dates[0]
                    ? moment(selectedItem.Permit.Inspection_Dates[0])
                        .utc()
                        .format("MM-DD-YYYY")
                    : null}
                </span>
              </div>
            </div>
            <div className="flex">
              <div className="flex-1">
                Comments:{" "}
                <span className="font-bold">{selectedItem.Permit.Comment}</span>
              </div>
              <div className="flex-1">
                Inspection Date 2:{" "}
                <span className="font-bold">
                  {selectedItem.Permit.Inspection_Dates &&
                  selectedItem.Permit.Inspection_Dates[1]
                    ? moment(selectedItem.Permit.Inspection_Dates[1])
                        .utc()
                        .format("MM-DD-YYYY")
                    : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <AliasModal
        open={!!showAliasModal}
        setIsOpen={setShowAliasModal}
        table="chemigations"
        tableId={showAliasModal}
        refreshData={refresh}
      />
    </div>
  );
};

export default ChemigationPage;
