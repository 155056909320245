import React, { useState, useRef, useEffect } from "react";
import Modal from "../common/Modal";
import Navigation from "../common/Navigation";
import { steps } from "./configs";
import { FormikProvider, useFormik } from "formik";
import Button from "../common/Button";
import { twMerge } from "tailwind-merge";
import toast from "react-hot-toast";
import { SoilSchema } from "../../utils/validation";
import Input from "../common/form/Input";
import Toggle from "../common/Toggle";
import DatePicker from "../common/form/DatePicker";
import SoilLayer from "./SoilLayer";
import SoilTestResult from "./SoilTestResult";
import request from "../../utils/fetch";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import fileUpload from "../../utils/fileUpload";
import Select from "../common/form/Select";

export const SoilStepperModalContent = ({
  formik,
  isLoading,
  renderMap,
  file,
  setFile,
  initNumberLayer = 1,
  customBackFunction,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [numberLayer, setNumberLayer] = useState(initNumberLayer);
  const [error, setError] = useState();
  const layerRef = useRef();

  useEffect(() => {}, [formik.values]);

  function onDrop(file) {
    setFile(file);
  }

  const currentStep = steps[activeStep];

  const renderItem = (
    {
      label,
      key,
      suffix,
      bool,
      textarea,
      number,
      date,
      comment,
      elementClassName,
      soilLayer,
      dropdown,
      options,
    },
    index
  ) => {
    const labelToShow = label || key.split("_").join(" ");

    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );

    if (bool) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <Toggle
            key={key}
            value={formik.values[key]}
            label={labelToShow}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
          {inputComment}
        </div>
      );
    }
    if (date) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <DatePicker
            label={labelToShow}
            value={formik.values[key]}
            onChange={(e) => {
              formik.setFieldValue(key, e.target.value);
            }}
          />
        </div>
      );
    }
    if (soilLayer) {
      return (
        <SoilLayer
          key={`${key}_${index}`}
          formik={formik}
          numberLayer={numberLayer}
          setNumberLayer={setNumberLayer}
          error={error}
        />
      );
    }
    if (dropdown) {
      return (
        <Select
          id={key}
          name={key}
          label={labelToShow}
          extraOptions={options}
          onChange={(option) => {
            formik.setFieldValue(key, option);
          }}
          value={formik.values[key]}
        />
      );
    }
    return (
      <div key={`${key}_${index}`} className={elementClassName || "col-span-1"}>
        <Input
          name={key}
          label={labelToShow}
          textarea={textarea}
          suffix={suffix}
          type={number ? "number" : "text"}
          onChange={(e) => {
            formik.setFieldValue(
              key,
              number && e.target.value
                ? parseFloat(e.target.value)
                : e.target.value
            );
            formik.setFieldTouched(key);
          }}
        />
        {inputComment}
      </div>
    );
  };

  const nextStep = (e) => {
    e.preventDefault();
    let isValid = currentStep.validation
      ? currentStep.validation(formik.values, numberLayer)
      : true;
    if (
      layerRef?.current &&
      currentStep.type === "test_result" &&
      !layerRef?.current.nextLayer()
    ) {
      isValid = "Please enter all required fields";
      setError(isValid);
    }
    if (isValid === true) {
      setActiveStep(activeStep + 1);
      setError(null);
      if (steps[activeStep + 1].type === "test_result" && layerRef?.current) {
        layerRef?.current.initialValidation();
      }
    } else if (currentStep.type !== "test_result") {
      setError(isValid);
      formik.validateForm();
    }
  };

  const previousStep = (e) => {
    e.preventDefault();
    setActiveStep(activeStep - 1);
    setError(null);
  };

  return (
    <div className="p-2 flex relative">
      <Navigation
        steps={steps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <div className="w-3/5 bg-white dark:bg-gray-600 rounded-lg">
        <div className="relative p-4 border-b border-gray-200 text-center flex items-center justify-center">
          <div className="text-lg">{currentStep.title}</div>
          {activeStep !== 0 && (
            <ChevronLeftIcon
              width={40}
              height={40}
              className="absolute left-0 cursor-pointer"
              onClick={previousStep}
            />
          )}
        </div>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              {steps.map((step, stepIdx) => {
                if (step.type === "test_result") {
                  return (
                    <div
                      key={stepIdx}
                      className={twMerge(
                        "invisible opacity-0",
                        activeStep === stepIdx ? "visible opacity-100" : "h-0",
                        step.className
                      )}
                    >
                      <SoilTestResult
                        ref={layerRef}
                        key={stepIdx}
                        formik={formik}
                        fields={step.fields}
                        numberLayer={numberLayer}
                      />
                    </div>
                  );
                } else if (step.type === "map") {
                  return (
                    <div
                      className={twMerge(
                        "w-full h-[472px] invisible opacity-0",
                        activeStep === stepIdx
                          ? "visible opacity-100 content_active"
                          : "h-0"
                      )}
                    >
                      {renderMap({ iconSize: 1.5 })}
                    </div>
                  );
                } else if (step.type === "submit") {
                  return (
                    <div
                      key={stepIdx}
                      className={twMerge(
                        "flex flex-col justify-center items-center invisible opacity-0",
                        activeStep === stepIdx
                          ? "visible opacity-100 content_active"
                          : "h-0",
                        step.className
                      )}
                    >
                      {setFile ? (
                        <div className="flex items-center">
                          <Button
                            type="button"
                            priority="secondary"
                            className="px-4"
                            onClick={(e) => {
                              e.stopPropagation();
                              fileUpload(
                                ".jpeg,.jpg,.png,.tiff,.pdf,.docx",
                                onDrop
                              );
                            }}
                          >
                            Upload Soil Sample Result File or Picture
                          </Button>
                          {file && (
                            <div className="ml-3 flex-1 italic line-clamp-1">
                              {file.name}
                            </div>
                          )}
                        </div>
                      ) : null}
                      <Button
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                        className="my-5 w-fit float-right"
                      >
                        Submit Sample
                      </Button>
                    </div>
                  );
                }
                return (
                  <div
                    key={stepIdx}
                    className={twMerge(
                      "grid grid-cols-1 md:grid-cols-2 gap-5 invisible opacity-0",
                      activeStep === stepIdx
                        ? "visible opacity-100 content_active"
                        : "h-0",
                      step.className
                    )}
                  >
                    {(step.fields || []).map((item, index) =>
                      renderItem(item, index)
                    )}
                  </div>
                );
              })}
              <div className="flex items-center justify-end mt-3">
                <Button
                  type="button"
                  className={`w-fit z-100 ${
                    activeStep < steps.length - 1 ? "" : "invisible"
                  }`}
                  onClick={nextStep}
                >
                  Next
                </Button>
              </div>
              <div
                className={twMerge(
                  "mt-2 text-red-500 invisible opacity-0 text-end",
                  error ? "visible opacity-100 content_active" : "h-0"
                )}
              >
                {error}
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      {customBackFunction ? (
        <ChevronLeftIcon
          width={40}
          height={40}
          className="absolute left-0 bottom-6 cursor-pointer"
          onClick={customBackFunction}
        />
      ) : null}
    </div>
  );
};

const SoilStepper = ({
  open,
  setIsOpen,
  soilId,
  refreshData,
  renderMap,
  tractAcres,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      sample_date: new Date().toISOString(),
      lab_date: new Date().toISOString(),
      soil_layer_1_top_depth: 0,
      composite_sample: false,
      sample_id: `Sample ${new Date().getFullYear()}`,
      acres_represented: tractAcres || 0,
    },
    validationSchema: SoilSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const result = await request({
          method: "post",
          url: `/soils/${soilId}/samples`,
          data: values,
        });
        if (result && file) {
          const formData = new FormData();
          formData.append("file", file);
          const headers = { "Content-Type": "multipart/form-data" };
          await request({
            method: "post",
            url: `/soils/${soilId}/samples/${result}/AddSampleFile`,
            data: formData,
            headers,
          });
        }
        refreshData();
        toast.success("Successfully added soil sample");
        setIsOpen(false);
        setFile(null);
        resetForm();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      isOpen={open}
      close={() => {
        setIsOpen(false);
        formik.resetForm();
      }}
      Content={SoilStepperModalContent}
      className="bg-[#d9d9d9]"
      contentProps={{
        formik,
        isLoading,
        renderMap,
        file,
        setFile,
      }}
    />
  );
};

export default SoilStepper;
