import { CheckIcon } from "@heroicons/react/20/solid";
import React from "react";

const steps = ["Tract", "Water", "Crop", "Soils", "Confirmation"];

const ReportProgress = ({ step, setStep }) => {
  return (
    <div className="flex items-center gap-x-5">
      {steps.map((item, index) => (
        <div key={index} className="flex items-center gap-x-2 cursor-pointer" onClick={() => setStep(index)}>
          <div
            className={`rounded-[10px] w-12 h-12 min-w-12 flex items-center justify-center ${
              index === step
                ? "bg-light-blue shadow-md"
                : index < step
                ? "bg-primary-light shadow-md"
                : "bg-white border border-black"
            }`}
          >
            {index === step ? (
              <CheckIcon color="white" className="w-6 h-6" />
            ) : (
              <span
                className={`text-lg ${
                  index <= step ? "text-white" : "text-black"
                }`}
              >
                {index + 1}
              </span>
            )}
          </div>
          <span
            className={`text-lg ${
              index === step
                ? "text-light-blue"
                : index < step
                ? "text-primary-light"
                : "text-black"
            }`}
          >
            {item}
          </span>
          {index < steps.length - 1 && (
            <img
              src={
                index === step
                  ? "/LineOnHold.svg"
                  : index < step
                  ? "/LineActive.svg"
                  : "/LineInactive.svg"
              }
              alt="progress-line"
              className="w-[73px] h-[8px]"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default ReportProgress;
