import { useState, useEffect, useCallback } from 'react';
import request from '../utils/fetch';

async function execute(url, setData, setLoading = () => {}) {
  try {
    let data = await request({ url });

    if (!data) data = [];

    if (data?.error === 'Authentication failed') {
      window.location.href = '/error?msg=unauthorized';
    }
    if (data?.error === 'Incorrect account config') {
      window.location.href = '/error?msg=incorrect_account_config';
    }

    setData(data);
    setLoading(false);
  } catch (error) {
    setData(null);
    setLoading(false);
    console.warn(error);
  }
}

export default function useFetch(url, options = {}) {
  const [data, setData] = useState(null);

  const includeUrl = options.includeUrl || false;
  const updateData = useCallback((newData) => {
    if (includeUrl) setData({ ...newData, _url: url });
    else setData(newData);
  }, [url, includeUrl]);

  useEffect(() => {
    if (options.prevent) return

    execute(url, updateData);
  }, [url, updateData, options.prevent]);

  async function refresh(refreshedData) {
    if (refreshedData) {
      updateData(refreshedData);
    } else {
      await execute(url, updateData);
    }
  }

  return { data, refresh };
}

export function useLazyFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  return [
    (urlSuffix) => {
      if (urlSuffix && urlSuffix[0] !== '/') urlSuffix = `/${urlSuffix}`;
      setLoading(true);
      execute(urlSuffix ? url + urlSuffix : url, setData, setLoading);
      setData(null);
    },
    {
      data,
      loading
    },
  ];
}
