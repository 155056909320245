import React from "react";
import PropTypes from "prop-types";
import { Switch } from "@headlessui/react";
import { twMerge } from "tailwind-merge";

export default function Toggle({
  value = false,
  onChange,
  label,
  className = "items-center",
  disabled,
  classSwitch = "",
  classSpan = "",
  labelClassName,
}) {
  return (
    <Switch.Group as="div" className={twMerge("flex", className)}>
      <Switch
        checked={value}
        onChange={onChange}
        className={twMerge(
          value ? "bg-primary" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11",
          "border-2 border-transparent rounded-full cursor-pointer",
          "transition-colors ease-in-out duration-200 focus:outline-none",
          "focus:ring-2 focus:ring-offset-2 focus:ring-primary-light",
          classSwitch
        )}
        disabled={disabled}
      >
        <span
          aria-hidden="true"
          className={twMerge(
            value ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full",
            "bg-white shadow transform ring-0 transition",
            "ease-in-out duration-200",
            classSpan
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span
          className={twMerge(
            "text-sm font-medium text-gray-900 dark:text-white",
            labelClassName
          )}
        >
          {label}
        </span>
      </Switch.Label>
    </Switch.Group>
  );
}

Toggle.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
