import React, { useState, useCallback, useMemo } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { SoilSchema } from "../../utils/validation";
import request from "../../utils/fetch";
import { SoilStepperModalContent } from "../soil/SoilStepper";
import useFetch from "../../hooks/useFetch";
import GenericMap from "../map/GenericMap";
import { getBoundBox } from "../../utils/turfHelpers";
import pointsWithinPolygon from "@turf/points-within-polygon";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";

const SoilReport = ({
  reportId,
  tractAcres,
  data: soilReportData,
  refreshData,
  activeReportStep,
  setReportStep
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedSoil, setSelectedSoil] = useState();

  const { data } = useFetch("/soils");

  const formik = useFormik({
    initialValues: soilReportData
      ? soilReportData
      : {
          sample_date: new Date().toISOString(),
          lab_date: new Date().toISOString(),
          soil_layer_1_top_depth: 0,
          composite_sample: false,
          sample_id: `Sample ${new Date().getFullYear()}`,
          acres_represented: tractAcres || 0,
        },
    validationSchema: SoilSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        await request({
          method: "patch",
          url: `/reports/${reportId}`,
          data: {
            soil_section: [values],
          },
        });
        toast.success("Successfully added soil sample");
        resetForm();
        refreshData();
        setReportStep(activeReportStep + 1);
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleSoilClick = useCallback(
    (type, id) => {
      const newSelectedSoil = (data?.soils || []).find(
        (item) => item._id === id
      );
      if (newSelectedSoil) {
        setSelectedSoil(newSelectedSoil);
      }
    },
    [data?.soils]
  );

  const renderMap = useCallback(() => {
    if (data) {
      let certGeo = null;
      const soilsGeo = convertToFeatureCollection(data.soils, "location_id");

      const allPoints = [];
      (data.soils || [])
        .filter((c) => c?.geometry?.coordinates?.length)
        .forEach((c) => {
          allPoints.push(c?.geometry?.coordinates);
        });

      let selectedGeo;
      if (selectedSoil) {
        (soilsGeo?.features || []).forEach((item) => {
          if (item.properties.id === selectedSoil._id) {
            item.properties.isSelected = true;
            selectedGeo = item;
          }
        });

        (certGeo?.features || []).forEach((item) => {
          if (pointsWithinPolygon(selectedGeo, item)?.features?.length) {
            item.properties.isSelected = true;
          }
        });
      }

      const viewport = calculatePolygonViewport({
        type: "Polygon",
        coordinates: [allPoints],
      }) || {
        latitude: 41.5886072190021,
        longitude: -103.58719705449793,
        zoom: 12.950430835225765,
      };

      let bboxMap;
      try {
        bboxMap = getBoundBox(
          selectedGeo
            ? [selectedGeo]
            : [...soilsGeo.features, ...(certGeo?.features || [])]
        );
      } catch (e) {
        console.warn(e);
      }
      const shouldAddBBox = bboxMap?.some(
        (i) => i !== Infinity && i !== -Infinity
      );

      return (
        <GenericMap
          alwaysShowIcons
          soilsGeo={soilsGeo}
          certGeo={certGeo}
          viewportOverride={viewport}
          bboxMap={shouldAddBBox ? bboxMap : null}
          bboxPadding={100}
          iconSize={1.5}
          onIconClick={handleSoilClick}
        />
      );
    }
    return null;
  }, [data, handleSoilClick, selectedSoil]);

  const initNumberLayer = useMemo(() => {
    let numLayer = 1;
    if (soilReportData) {
      for (const key in soilReportData) {
        const match = key.match(/^soil_layer_(\d+)_/);
        if (match) {
          const layerNumber = parseInt(match[1], 10);
          if (layerNumber > numLayer) {
            numLayer = layerNumber;
          }
        }
      }
    }
    return numLayer;
  }, [soilReportData]);

  return (
    <SoilStepperModalContent
      formik={formik}
      isLoading={isLoading}
      renderMap={renderMap}
      initNumberLayer={initNumberLayer}
      customBackFunction={() => setReportStep(activeReportStep - 1)}
    />
  );
};

export default SoilReport;
