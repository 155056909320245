import React from "react";
import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

const ReportStatus = ({ status }) => {
  return (
    <div
      className={`w-fit rounded-md px-3 py-1 flex items-center text-white font-bold gap-x-1 ${
        status === "Due"
          ? "bg-red-600"
          : status === "In Progress"
          ? "bg-orange-400"
          : status === "Done" || status === "Completed"
          ? "bg-primary-light"
          : "bg-gray-1"
      }`}
    >
      {status === "Due" ? (
        <XCircleIcon className="w-5 h-5" />
      ) : status === "In Progress" ? (
        <ArrowRightCircleIcon className="w-5 h-5" />
      ) : status === "Done" ? (
        <CheckCircleIcon className="w-5 h-5" />
      ) : null}
      {status}
    </div>
  );
};

export default ReportStatus;
