import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import HighLight from "../highlight";
import TableData from "../table";
import useFetch from "../../hooks/useFetch";
import Loading from "../common/Loading";
import UsageChart from "./UsageChart";
import { useSearchParams } from "react-router-dom";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import AliasModal from "../common/AliasModal";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import toDataUrl from "../../utils/toDataUrl";
import Button from "../common/Button";
import Select from "../common/form/Select";
import { useMapRender } from "../../hooks/map/useMapRender";

const LIMIT = 100;
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
      className="text-xs"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const TractPage = () => {
  const [page, setPage] = useState(1);
  const [canLoadMore, setCanLoadMore] = useState(false);
  const [isLoading, setShowLoading] = useState(true);
  const [showAliasModal, setShowAliasModal] = useState(null);
  const [selectedItem, setSelectedItem] = useState();
  const [year, setYear] = useState(moment().year());
  let [searchParams] = useSearchParams();
  const { data, refresh } = useFetch("/tracts");
  const { data: usageData } = useFetch("/tracts/usage");

  useEffect(() => {
    if (data) {
      setShowLoading(false);
      if (data.TractsDetail?.length && !selectedItem) {
        if (searchParams.get("id")) {
          const defaultSelectedTract = data.TractsDetail.find(
            (item) =>
              item._id === searchParams.get("id") ||
              item.Cert_ID === searchParams.get("id")
          );
          if (defaultSelectedTract) {
            setSelectedItem(defaultSelectedTract);
            return;
          }
        }
        setSelectedItem(data.TractsDetail[0]);
      }
    }
  }, [data, searchParams, selectedItem]);

  const handleScrollBottom = () => {
    // if (LIMIT * page < TRACTS.length) {
    //   setTimeout(() => {
    //     setPage(page + 1);
    //   }, 3000);
    // } else {
    //   setCanLoadMore(false);
    // }
  };

  const yearOptions = useMemo(() => {
    const years = [];
    const currentYear = moment().year();
    for (let year = currentYear; year >= currentYear - 4; year--) {
      years.push(year);
    }
    return years;
  }, []);

  const generateReport = useCallback(async () => {
    try {
      const [logo, reportData, { default: createReport }] = await Promise.all([
        toDataUrl("/PC_Horz_Color.png"),
        request({
          url: `/tracts/${selectedItem?._id}/ReportData?year=${year}`,
        }),
        import("../../reports/generate/NRCSReport"),
      ]);

      await createReport({
        data: reportData,
        logo,
        year,
      });
    } catch (e) {
      toast.error(e.response?.data?.error_message || e.message);
    }
  }, [selectedItem?._id, year]);

  const tracts = useMemo(() => {
    if (data) {
      return (
        <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full p-3">
          <div className="flex flex-col xl:flex-row xl:items-center justify-between">
            <div className="font-bold text-2xl">Tract Details</div>
            <div className="flex items-center mt-4 xl:mt-0">
              <div className="flex items-center gap-x-2 mr-3">
                <span>Year</span>
                <Select
                  value={String(year)}
                  onChange={setYear}
                  extraOptions={yearOptions}
                />
              </div>
              <Button priority="secondary" onClick={generateReport}>
                NRCS Report
              </Button>
            </div>
          </div>
          <TableData
            enableScrollIntoItem
            selectedItem={selectedItem}
            data={data.TractsDetail || []}
            page={page}
            canLoadMore={canLoadMore}
            handleScrollBottom={handleScrollBottom}
            headerItems={
              <>
                <div className="w-2/12 text-center">TRACT ID</div>
                <div className="w-3/12 text-left">TYPE</div>
                <div className="w-2/12 text-left">USE TYPE</div>
                <div className="w-3/12 text-left">ALIAS</div>
                <div className="w-2/12 text-left">ACRES</div>
              </>
            }
            renderRow={(item, index) => (
              <div
                className={`flex w-full py-4 cursor-pointer ${
                  selectedItem?.Cert_ID === item.Cert_ID ? "bg-gray-1" : ""
                }`}
                onClick={() => setSelectedItem(item)}
              >
                <div className="w-2/12 text-center">{item.Cert_ID}</div>
                <div className="w-3/12">{item.Category}</div>
                <div className="w-2/12">{item.cert_type}</div>
                <div className="w-3/12 flex">
                  {item.Alias}
                  <PencilSquareIcon
                    className="w-5 h-5 cursor-pointer ml-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAliasModal(item._id);
                    }}
                  />
                </div>
                <div className="w-2/12">{(item.acres || 0).toFixed(1)}</div>
              </div>
            )}
          />
        </div>
      );
    }
    return null;
  }, [
    canLoadMore,
    data,
    generateReport,
    page,
    selectedItem,
    year,
    yearOptions,
  ]);

  const usageChart = useMemo(() => {
    const itemUsageData = usageData?.[selectedItem?._id];
    if (selectedItem && itemUsageData) {
      return (
        <UsageChart usageData={itemUsageData} certId={selectedItem.Cert_ID} />
      );
    }
    return null;
  }, [selectedItem, usageData]);

  const handleOnTractClick = (type, id) => {
    const newSelectedTract = data.TractsDetail.find((item) => item._id === id);
    if (newSelectedTract) {
      setSelectedItem(newSelectedTract);
    }
  };

  const { renderMap } = useMapRender({
    tracts: data?.TractsDetail,
    mapComponent: 'tract',
    selectedItem,
    extraMapProps: {
      expandable: true,
      irrigatedEditable: true,
      editingEndpoint: "certs",
      onIconClick: handleOnTractClick,
    },
  });

  const highlight = useMemo(() => {
    if (data) {
      const irrChartData = [
        ...(data.total_flood_acres
          ? [{ name: "Flood", value: data.total_flood_acres }]
          : []),
        ...(data.total_sprinker_acres
          ? { name: "Sprinkler", value: data.total_sprinker_acres }
          : []),
        ...(data.total_other_acres
          ? [{ name: "Other", value: data.total_other_acres || 0 }]
          : []),
      ];
      const COLORS = ["#9DC34F", "#65A7DD", "#396485"];
      return [
        {
          title: "Irrigation Type",
          chartProps: {
            customChart: (
              <PieChart width={150} height={150}>
                <Pie
                  dataKey="value"
                  isAnimationActive={false}
                  data={irrChartData}
                  cx="50%"
                  cy="50%"
                  outerRadius={70}
                  fill="#8884d8"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {irrChartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            ),
          },
        },
        {
          title: "Total Tracts",
          value: data.total_tracts,
        },
        {
          title: "Total Certified Acres",
          value: data.total_cert_acres,
        },
      ];
    }
    return null;
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">Tracts Overview</h2>
      <div className="my-6 flex gap-x-4">
        {highlight &&
          highlight.map((item, index) => (
            <HighLight key={index} data={item} className="w-full" />
          ))}
      </div>
      <div className="flex flex-col lg:flex-row my-10 gap-x-5 items-start">
        <div className="w-full lg:flex-1 h-[472px]">{renderMap()}</div>
        <div className="w-full lg:flex-1 mt-4 lg:mt-0">{tracts}</div>
      </div>
      {usageChart}
      <AliasModal
        open={!!showAliasModal}
        setIsOpen={setShowAliasModal}
        table="tracts"
        tableId={showAliasModal}
        refreshData={refresh}
      />
    </div>
  );
};
export default TractPage;
