import React, { useEffect, useMemo, useState } from "react";
import HighLight from "../highlight";
import calculatePolygonViewport from "../../utils/calculatePolygonViewport";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import { getBoundBox } from "../../utils/turfHelpers";
import GenericMap from "../map/GenericMap";
import Loading from "../common/Loading";
import { getChartData, getLastestValueForChart } from "../../utils/helpers";
import request from "../../utils/fetch";
import CostAnalysis from "./CostAnalysis";
import { useMapRender } from "../../hooks/map/useMapRender";

const NitratesPage = () => {
  const [selectedTract, setSelectedTract] = useState();
  const [isLoading, setShowLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await request({ url: "/nitrates" });
        setData(response);
      } catch (e) {
        setError(e.response?.data?.error_message || e.message);
      } finally {
        setShowLoading(false);
      }
    }
    fetchData();
  }, []);

  const tractDataMapping = useMemo(
    () =>
      Object.entries(data?.nitrate_tracts || {}).map((item) => ({
        ...item[1],
        _id: item[0],
        geometry: item[1].geom,
        Category: "Certified Acres",
      })),
    [data]
  );

  useEffect(() => {
    if (data) {
      setShowLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedTract && tractDataMapping?.length) {
      setSelectedTract(tractDataMapping[0]);
    }
  }, [tractDataMapping, selectedTract]);

  const map = useMemo(() => {
    if (data) {
      const allPoints = [];
      (tractDataMapping || [])
        .filter((c) => c?.geometry?.coordinates?.length)
        .forEach((c) => {
          if (c.geometry.type === "MultiPolygon") {
            c.geometry.coordinates
              .reduce((acc, [arr]) => [...acc, ...arr], [])
              .forEach((p) => allPoints.push(p));
          } else
            (c.geometry.coordinates[0] || []).forEach((p) => allPoints.push(p));
        });
      const viewport = calculatePolygonViewport({
        type: "Polygon",
        coordinates: [allPoints],
      }) || {
        latitude: 41.5886072190021,
        longitude: -103.58719705449793,
        zoom: 12.950430835225765,
      };
      let selectedGeo;
      const certGeo = convertToFeatureCollection(
        tractDataMapping || [],
        "tract_id",
        ["Category"]
      );
      (certGeo?.features || []).forEach((item) => {
        const isSelected = item.properties.id === selectedTract?._id;
        item.properties.isSelected = isSelected;
        if (isSelected) {
          selectedGeo = item;
        }
      });
      let bboxMap;
      try {
        bboxMap = getBoundBox([
          ...(selectedGeo ? [selectedGeo] : certGeo.features),
        ]);
      } catch (e) {
        console.warn(e);
      }
      const shouldAddBBox = bboxMap?.some(
        (i) => i !== Infinity && i !== -Infinity
      );

      return (
        <GenericMap
          certGeo={certGeo}
          viewportOverride={viewport}
          expandable
          editingEndpoint="certs"
          bboxMap={shouldAddBBox ? bboxMap : undefined}
          onIconClick={(type, id) => {
            const tractToSelect = tractDataMapping.find(
              (item) => item._id === id
            );
            if (tractToSelect) {
              setSelectedTract(tractToSelect);
            }
          }}
        />
      );
    }
    return null;
  }, [data, selectedTract, tractDataMapping]);

  const handleOnTractClick = (type, id) => {
    const tractToSelect = tractDataMapping.find((item) => item._id === id);
    if (tractToSelect) {
      setSelectedTract(tractToSelect);
    }
  };

  const { renderMap } = useMapRender({
    tracts: tractDataMapping || [],
    mapComponent: "tract",
    selectedItem: selectedTract,
    extraMapProps: {
      expandable: true,
      editingEndpoint: "certs",
      onIconClick: handleOnTractClick,
    },
  });

  const highlight = useMemo(() => {
    if (!data) {
      return null;
    }
    return [
      {
        title: "Nitrogen Application Rate",
        value: `${getLastestValueForChart(
          data,
          "nitrate_application_rate"
        )} lbs / ac`,
        percentage: data.nitrate_application_rate_change,
        chartProps: {
          chartData: getChartData(data, "nitrate_application_rate"),
        },
      },
      {
        title: "Nitrogen Use Efficiency",
        value: `${getLastestValueForChart(
          data,
          "nitrate_eff_bu_per_lbs"
        )} bu / lbs`,
        percentage: data.nitrate_eff_bu_per_lbs_change,
        chartProps: {
          chartData: getChartData(data, "nitrate_eff_bu_per_lbs"),
          lineColor: "#9DC34F",
        },
      },
      {
        title: "NRD Average Nitrogen Efficiency",
        value: `${getLastestValueForChart(
          data,
          "nrd_eff_bu_per_lbs"
        )} bu / lbs`,
        percentage: data.nrd_eff_bu_per_lbs_change,
        chartProps: {
          chartData: getChartData(data, "nrd_eff_bu_per_lbs"),
          lineColor: "#396485",
        },
      },
    ];
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">Nitrogen</h2>
      {error ? <p className="text-red-500 mt-5 text-xl">{error}</p> : null}
      <div className="my-6 flex gap-x-4">
        {highlight &&
          highlight.map((item, index) => (
            <HighLight key={index} data={item} className="w-full" />
          ))}
      </div>
      {data ? (
        <div className="flex my-10 gap-x-5 items-start">
          <div className="w-3/5 h-[472px]">{renderMap()}</div>

          <div className="flex-1 min-h-[472px] bg-white dark:bg-gray-900 dark:text-white p-3 max-w-2xl mx-auto rounded-lg">
            {selectedTract && (
              <>
                <div className="flex items-center justify-between mb-5">
                  <h5 className="font-bold text-xl">
                    Tract {selectedTract.tract_id} Information:
                  </h5>
                </div>
                <div className="pl-5">
                  <div className="flex items-center justify-between mb-3">
                    <span>Average N From Soil</span>
                    <span>{selectedTract.average_soil_n} lb / ac</span>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                    <span>Soil Organic Matter Credit</span>
                    <span>{selectedTract.soil_om_nitrate} lb / ac</span>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                    <span>Legume N Credit</span>
                    <span>{selectedTract.legume_crop_n} lb / ac</span>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                    <span>Irrigation N Credit</span>
                    <span>{selectedTract.irrigation_n} lb / ac</span>
                  </div>
                  <div className="flex items-center justify-between mb-3">
                    <span>Manure N Credit</span>
                    <span>{selectedTract.manure_n} lb / ac</span>
                  </div>
                </div>
                <div className="pl-5 pt-3 flex items-center justify-between border-t border-gray-1">
                  <span>Total N Credit</span>
                  <span>{selectedTract.total_nitrate_credit} lb / ac</span>
                </div>
                <div className="pl-5 pt-3 flex items-center justify-between">
                  <span>Corn Yield Goal</span>
                  <span>{selectedTract.corn_yield_goal} bu / ac</span>
                </div>
                <div className="pl-5 pt-3 flex items-center justify-between">
                  <span>Calculated N Required</span>
                  <span>{selectedTract.required_nitrate} lbs / ac</span>
                </div>
                <div className="font-bold pl-5 pt-3 flex items-center justify-between">
                  <span className="text-xl">
                    Recommended Nitrogen Application
                  </span>
                  <span>{selectedTract.nitrate} lbs / ac</span>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
      {selectedTract && <CostAnalysis selectedTract={selectedTract} />}
    </div>
  );
};

export default NitratesPage;
