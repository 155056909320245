import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, Transition } from "@headlessui/react";
import IconButton from "./IconButton";
import { twMerge } from "tailwind-merge";

const TraySlideOut = ({
  title = "",
  children,
  open = false,
  onClose = () => {},
  size = "max-w-md",
  className = "",
  initialFocus,
}) => {
  const closeTray = () => {
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={closeTray}
        initialFocus={initialFocus}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen ${size}`}
                >
                  <div
                    className={twMerge(
                      "flex h-full flex-col overflow-y-scroll bg-white shadow-xl dark:bg-gray-900 dark:text-white",
                      className
                    )}
                  >
                    <div className="bg-primary py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-white">
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <IconButton
                            onClick={() => onClose()}
                            icon="cancel"
                            color="white"
                            small
                          />
                        </div>
                      </div>
                    </div>
                    {children}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

TraySlideOut.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  size: PropTypes.string,
};

export default TraySlideOut;
