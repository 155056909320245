import React from "react";
import ReportStatus from "./ReportStatus";
import { useMapRender } from "../../hooks/map/useMapRender";

const TractInfo = ({ data }) => {
  const { renderMap } = useMapRender({
    tracts: [data],
    mapComponent: 'tract'
  });

  return (
    <div className="flex items-center justify-between gap-10 bg-[#F3F3F3] rounded-xl p-5">
      <div className="w-1/4 aspect-square">{renderMap()}</div>
      <div className="flex-1 flex flex-col items-center">
        <div className="flex justify-between w-full">
          <span>Alias: {data.Alias}</span>
          <span>Tract Id: {data.Cert_ID}</span>
          <span>Water Source: {data.Water_Sources}</span>
        </div>
        <div className="flex justify-between mt-5 w-full">
          <span className="flex items-center gap-x-1">
            Status: <ReportStatus status={data.status || "N/A"} />
          </span>
          <span>Year: {data.report_year}</span>
          <span>Type: {data.cert_type}</span>
        </div>
        <div className="w-1/2 flex justify-between mt-5">
          <span>Phase Area:</span>
          <span>Sub Area:</span>
        </div>
      </div>
    </div>
  );
};

export default TractInfo;
